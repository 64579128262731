import React, { useEffect, useState } from "react";
import SelectorItem from "./components/SelectorItem";

export default ({items, onChange, loading = false, titulo, selectedItem, showInfo, className, icon, leyendaSinItems, extraContent, disabled, ...props}) => {
  const [folded, setFolded] = useState(props.folded);
  const [listHeight, setListHeight] = useState('0px');
  const unfoldMe = (event) => {
    setFolded(!folded);
  }

  useEffect(() => {
    if (folded) {
      setListHeight('0px');
    } else {
      setListHeight((40 * ((items ? items.length : 0) + 1)) + 'px');
    }
  }, [folded, items]);

  useEffect(() => {
    setFolded(props.folded);
  }, [props.folded]);

  return (
    <div className={"selector__wrapper" + (folded ? ' is-folded' : ' is-unfolded') + (className ? ' '+className : '')}>
      <button disabled={disabled} className={"selector "} onClick={unfoldMe}>
        <div className="selector__icon">{(typeof(icon) === 'string') ? <i className={"icon--" + icon}></i> : icon}</div>
        <h1 className="selector__titulo">{loading ? 'Cargando...' : (selectedItem ? selectedItem.nombre : titulo)}</h1>
        {showInfo ? <span className="selector__info">{showInfo(selectedItem)}</span> : null}
        <div className="selector__folded"><img className="selector-chevron" src="/img/chevron.svg" alt="" /></div>
      </button>
      <div className="selector-list" style={{height: listHeight}}>
        <div className="selector-list-header">
          <h1 className="selector-list__titulo">{items && (items.length === 0) && leyendaSinItems ? leyendaSinItems : titulo}</h1><button className="selector-list__control"><div><img className="selector-chevron" src="/img/chevron.svg" alt="" /></div></button>
        </div>
        <ul className="selector-list__items">
          {items && items.map((item, index) => <li key={index} className="selector-list__item">
            <SelectorItem texto={item.nombre} isSelected={selectedItem === item} onClick={() => {
              unfoldMe();
              if (onChange) {onChange(item)}
            }} >
              {showInfo ? <span className="info">{showInfo(item)}</span> : null}
              {extraContent ? extraContent(item) : null}
            </SelectorItem>
          </li>)}
        </ul>
      </div>
    </div>
  );
}